import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useEffect, useRef, useState } from "react";

import appState from "~/library/appState";

import Elem from "./Elem";
import NewElemButton from "./NewElemButton";
import PanelText from "../../components/PanelText";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled.div(({ theme }) => ({
  flex: "1",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

const CreateContainer = styled.div(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
  flexShrink: "0",
  borderBottom: "1px solid #444444",
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ObjectsContainer = styled.div(({ theme }) => ({
  flex: "1",
  position: "relative",
  display: "flex",
  overflow: "hidden",
}));

const ObjectsContainerContent = styled.div(({ theme }) => ({
  flex: "1",
  position: "relative",
  display: "flex",
  padding: theme.spacing(1, "4px", 1, "4px"),
  overflowX: "auto",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "9px",
    borderLeft: "1px solid #3A3A3A",
  },
  "&::-webkit-scrollbar:horizontal": {
    height: "9px",
    borderTop: "1px solid #3A3A3A",
  },
  "&::-webkit-scrollbar-corner": {
    background: "#3A3A3A",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "rgba(121, 121, 121, 0.4)",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "rgba(100, 100, 100, 0.7)",
  },
  "&::-webkit-scrollbar-thumb:active": {
    background: "rgba(191, 191, 191, 0.4)",
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ObjectsContainerContentWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  flex: "1",
}));

const OverflowShadowContainer = styled.div<{ leftSide?: boolean }>(
  ({ leftSide }) => ({
    position: "absolute",
    width: "40px",
    height: "100%",
    top: "0",
    zIndex: "1000",
    overflow: "scroll",
    pointerEvents: "none",
    "&::-webkit-scrollbar": {
      width: "9px",
      height: "9px",
      backgroundColor: "transparent",
      border: "none",
    },
    "&::-webkit-scrollbar-corner": {
      backgroundColor: "transparent",
    },
    ...(leftSide
      ? {
          left: "0",
          overflowY: "hidden",
        }
      : {
          right: "0",
        }),
  }),
);

const OverflowShadow = styled.div<{ visible?: boolean; leftSide?: boolean }>(
  ({ visible, leftSide }) => ({
    width: "100%",
    transition: "all 0.3s",
    opacity: visible ? "1" : "0",
    ...(leftSide
      ? {
          background:
            "linear-gradient(90deg, rgba(40,40,40,1) 0%, rgba(40,40,40,0) 100%)",
          height: "100%",
        }
      : {
          background:
            "linear-gradient(90deg, rgba(40,40,40,0) 0%, rgba(40,40,40,1) 100%)",
          height: "120%",
        }),
  }),
);

const ScenePanel = () => {
  const objectsContainerRef = useRef<any>(null);
  const [overflowRightShadowVisible, setOverflowRightShadowVisible] =
    useState(false);
  const [overflowLeftShadowVisible, setOverflowLeftShadowVisible] =
    useState(false);

  const dragEventCount = useRef(0);
  const [draggingOver, setDraggingOver] = useState(false);
  const creatingNewElement = useRef(false);

  // Determine if overflow shadows should be visible
  useEffect(() => {
    const shadowCheckInterval = setInterval(() => {
      const objectsContainerElem: HTMLElement | undefined =
        objectsContainerRef.current;

      if (objectsContainerElem) {
        const hasScrollbar =
          objectsContainerElem.scrollWidth > objectsContainerElem.clientWidth;

        const isScrolledToEnd =
          objectsContainerElem.scrollLeft + objectsContainerElem.clientWidth >
          objectsContainerElem.scrollWidth - 5;

        const isScrolledToStart = objectsContainerElem.scrollLeft < 5;

        setOverflowRightShadowVisible(hasScrollbar && !isScrolledToEnd);
        setOverflowLeftShadowVisible(hasScrollbar && !isScrolledToStart);
      }
    }, 50);

    return () => {
      clearInterval(shadowCheckInterval);
    };
  }, []);

  const contentElem =
    appState.project?.elementHolder || document.createElement("div");

  const topLevelElems = Array.from(contentElem.children) as Array<HTMLElement>;

  const onElemClick = (elem: HTMLElement) => {
    appState.project?.setSelectedElements([elem]);
  };

  const onNewElemButtonClick = async (code: string) => {
    if (appState.project && !creatingNewElement.current) {
      creatingNewElement.current = true;

      // Delay to make sure that code formatting took place
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(undefined);
        }, 100);
      });

      const elemContainer = document.createElement("div");
      elemContainer.innerHTML = code;
      const elem = elemContainer.children[0] as HTMLElement;

      let insertedElem;

      // Append after selected elem
      if (appState.project.selectedElements?.length === 1) {
        const selectedElem = appState.project.selectedElements[0];
        appState.project.setSelectedElements(null);

        insertedElem = await appState.project.insertElement(elem, selectedElem);
      }

      // Prepend at the top
      else {
        insertedElem = await appState.project.insertElement(elem);
      }

      if (insertedElem) {
        appState.project.setSelectedElements([insertedElem]);
      }
    }

    creatingNewElement.current = false;
  };

  const staticDocumentRevision = appState.project?.staticDocumentRevision || 0;

  return (
    <Container>
      {/* Create */}
      {appState.project?.clientId && (
        <CreateContainer>
          <Stack direction="column" spacing={2}>
            <PanelText
              sx={{
                opacity: 0.5,
              }}
            >
              Click or drag to insert an object
            </PanelText>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={1}>
                <NewElemButton tag="m-group" onClick={onNewElemButtonClick} />
                <NewElemButton tag="m-cube" onClick={onNewElemButtonClick} />
                <NewElemButton tag="m-sphere" onClick={onNewElemButtonClick} />
                <NewElemButton
                  tag="m-cylinder"
                  onClick={onNewElemButtonClick}
                />
                <NewElemButton tag="m-plane" onClick={onNewElemButtonClick} />
              </Stack>
              <Stack direction="row" spacing={1}>
                <NewElemButton tag="m-model" onClick={onNewElemButtonClick} />
                <NewElemButton
                  tag="m-character"
                  onClick={onNewElemButtonClick}
                />
                <NewElemButton tag="m-image" onClick={onNewElemButtonClick} />
                <NewElemButton tag="m-video" onClick={onNewElemButtonClick} />
                <NewElemButton tag="m-audio" onClick={onNewElemButtonClick} />
              </Stack>
              <Stack direction="row" spacing={1}>
                <NewElemButton tag="m-prompt" onClick={onNewElemButtonClick} />
                <NewElemButton
                  tag="m-interaction"
                  onClick={onNewElemButtonClick}
                />
                <NewElemButton
                  tag="m-position-probe"
                  onClick={onNewElemButtonClick}
                />
                <NewElemButton
                  tag="m-chat-probe"
                  onClick={onNewElemButtonClick}
                />
                <NewElemButton tag="m-link" onClick={onNewElemButtonClick} />
              </Stack>
              <Stack direction="row" spacing={1}>
                <NewElemButton tag="m-label" onClick={onNewElemButtonClick} />
                <NewElemButton tag="m-light" onClick={onNewElemButtonClick} />
                <NewElemButton tag="m-frame" onClick={onNewElemButtonClick} />
              </Stack>
            </Stack>
          </Stack>
        </CreateContainer>
      )}

      {/* Objects */}
      <ObjectsContainer>
        {/* Shadows to indicate if items are overflowing in X axis */}
        <OverflowShadowContainer leftSide>
          <OverflowShadow leftSide visible={overflowLeftShadowVisible} />
        </OverflowShadowContainer>
        <OverflowShadowContainer>
          <OverflowShadow visible={overflowRightShadowVisible} />
        </OverflowShadowContainer>

        <ObjectsContainerContent ref={objectsContainerRef}>
          <ObjectsContainerContentWrapper
            onDragEnter={(evt) => {
              evt.preventDefault();

              dragEventCount.current++;
              if (dragEventCount.current === 1) {
                setDraggingOver(true);
              }
            }}
            onDragLeave={(evt) => {
              evt.preventDefault();

              dragEventCount.current--;
              if (dragEventCount.current === 0) {
                setDraggingOver(false);
              }
            }}
            onDragOver={(evt) => {
              evt.preventDefault();
            }}
            onDrop={(evt) => {
              evt.preventDefault();

              dragEventCount.current = 0;
              setDraggingOver(false);
            }}
            onClick={() => {
              appState.project?.setSelectedElements(null);
            }}
          >
            {topLevelElems.map((elem, index) => (
              <Elem
                key={String(staticDocumentRevision) + index + elem.outerHTML}
                elem={elem}
                onClick={onElemClick}
                zIndex={index}
                enableDropZones={draggingOver}
              />
            ))}
          </ObjectsContainerContentWrapper>
        </ObjectsContainerContent>
      </ObjectsContainer>
    </Container>
  );
};

export default {
  id: "scene",
  name: "Scene",
  Component: observer(ScenePanel),
};
