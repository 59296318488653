import styled from "@emotion/styled";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Icon from "~/components/Icon";
import Menu from "~/components/Menu";
import config from "~/config";
import LogoSymbolImage from "~/images/logo-symbol.svg";
import appState from "~/library/appState";
import {
  broadcastingModal,
  exploreModal,
  forkProjectModal,
  shareProjectModal,
  staticVersionsModal,
} from "~/modals";
import { MakeForkRequest } from "~/modals/ForkProject";

const Container = styled.div<{ broadcasting?: boolean }>(
  ({ broadcasting }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: `${broadcasting ? "#1C2C27" : "#303030"}`,
    borderBottom: "1px solid #444444",
    flexShrink: "0",
  }),
);

const ContentContainer = styled.div({
  height: "49px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: "0",
});

const Divider = styled.div({
  height: "100%",
  width: "1px",
  backgroundColor: "#444444",
});

const MenuButton = styled.div<{ menuOpen?: boolean }>(({ menuOpen }) => ({
  display: "flex",
  alignItems: "center",
  paddingLeft: "10px",
  height: "48px",
  width: "48px",
  backgroundColor: menuOpen ? "rgba(255, 255, 255, 0.1)" : "transparent",
  transition: "background-color 0.1s",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
}));

const ProjectVisibilityBadge = styled.div({
  height: "24px",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  borderRadius: "12px",
  border: "1px solid rgba(255, 255, 255, 0.2)",
  padding: "0 8px",
  fontSize: "12px",
  color: "rgba(255, 255, 255, 0.6)",
  lineHeight: "22px",
});

const BroadcastingBadge = styled.div({
  height: "24px",
  backgroundColor: "rgba(0, 228, 159, 0.1)",
  transition: "background-color 0.1s",
  borderRadius: "12px",
  border: "1px solid rgba(0, 228, 159, 0.3)",
  padding: "0 3px 0 8px",
  fontSize: "12px",
  color: "#00E49F",
  lineHeight: "22px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgba(0, 228, 159, 0.2)",
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PrimaryNavbarButton = styled(Button)(({ theme }) => ({
  height: "30px",
})) as typeof Button;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  boxSizing: "border-box",
  width: "30px",
  height: "30px",
})) as typeof Avatar;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledAvatarGroup = styled(AvatarGroup)(({ theme }) => ({
  ".MuiAvatarGroup-avatar": {
    boxSizing: "border-box",
    width: "30px",
    height: "30px",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    border: "none",
    fontSize: "12px",
    fontWeight: "500",
    color: "rgba(255, 255, 255, 0.6)",
  },
})) as typeof AvatarGroup;

type Props = {
  makeForkRequest: MakeForkRequest;
};

const ProjectNavbar = ({ makeForkRequest }: Props) => {
  const navigate = useNavigate();

  const menuButtonRef = useRef<any>(null);
  const [menuAnchorElem, setMenuAnchorElem] = useState<HTMLElement>();

  const client = Object.values(appState.project?.clients || {}).find(
    (c) => c.id === appState.project?.clientId,
  );
  const otherClients = Object.values(appState.project?.clients || {}).filter(
    (c) => c.id !== appState.project?.clientId,
  );

  const shareProject = () => {
    shareProjectModal({});
  };

  const staticVersions = () => {
    staticVersionsModal({});
  };

  const explore = () => {
    exploreModal({});
  };

  const forkProject = () => {
    forkProjectModal({
      projectId: appState.project?.id,
      makeForkRequest,
    });
  };

  const showBroadcastingInfo = () => {
    broadcastingModal({});
  };

  const menuItems = [];
  menuItems.push({
    title: "Projects",
    icon: "home",
    onClick: () => navigate("/projects"),
  });

  if (config.features.explorePage) {
    menuItems.push({
      title: "Explore",
      icon: "explore",
      onClick: () => navigate("/explore"),
    });
  }

  if (appState.project?.clientId) {
    menuItems.push({
      title: "Fork",
      icon: "fork",
      onClick: forkProject,
    });
  }

  menuItems.push({
    title: "-",
  });

  menuItems.push({
    title: "MML documentation",
    icon: "docs",
    onClick: () => {
      window.open("https://mml.io/docs", "_blank", "noopener");
    },
  });

  let rightContent = null;

  // Public project the user is not an owner/collaborator in
  if (appState.project?.isPublic && !appState.project?.clientId) {
    rightContent = (
      <PrimaryNavbarButton
        variant="contained"
        onClick={forkProject}
        startIcon={<Icon icon="fork" />}
      >
        Fork
      </PrimaryNavbarButton>
    );
  }

  // User is owner/collaborator
  else if (appState.project?.clientId) {
    rightContent = (
      <>
        <StyledAvatarGroup max={4} spacing={-4}>
          {otherClients.map((c, index) => (
            <StyledAvatar
              key={c.id + index}
              alt={c.user.name}
              src={c.user.picture}
              sx={{
                backgroundColor: `${c.color} !important`,
                border: `2px solid ${c.color} !important`,
                color: "white !important",
              }}
            />
          ))}
        </StyledAvatarGroup>

        <Box width="16px" />

        <StyledAvatar alt={client?.user.name} src={client?.user.picture} />

        <Box width="16px" />

        <PrimaryNavbarButton
          variant="contained"
          onClick={staticVersions}
          startIcon={<Icon icon="publish" />}
        >
          Static Versions
        </PrimaryNavbarButton>

        <Box width="16px" />

        {config.features.explorePage && (
          <>
            <PrimaryNavbarButton
              variant="contained"
              onClick={explore}
              startIcon={<Icon icon="publish" />}
            >
              Explore
            </PrimaryNavbarButton>
            <Box width="16px" />
          </>
        )}

        <PrimaryNavbarButton
          variant="contained"
          onClick={shareProject}
          startIcon={<Icon icon="share" />}
        >
          Share
        </PrimaryNavbarButton>
      </>
    );
  }

  // Temporary project
  // NOTE: Only possible when not logged in
  else if (appState.project && !appState.project.id) {
    rightContent = (
      <PrimaryNavbarButton
        variant="contained"
        href={`/auth/login/google`}
        target="_blank"
        startIcon={<Icon icon="google" />}
      >
        Sign in with Google
      </PrimaryNavbarButton>
    );
  }

  // Connecting to session
  if (appState.project?.session && !appState.project?.connected) {
    rightContent = <Typography fontSize={14}>Connecting...</Typography>;
  }

  return (
    <Container broadcasting={appState.project?.broadcasting}>
      <ContentContainer>
        {/* Left */}
        <Stack direction="row" height="100%" alignItems="center">
          {/* Menu button */}
          <MenuButton
            ref={menuButtonRef}
            menuOpen={Boolean(menuAnchorElem)}
            onClick={() => {
              setMenuAnchorElem(menuButtonRef.current);
            }}
          >
            <LogoSymbolImage />
            <Icon
              icon="arrowMenu"
              size="20px"
              opacity={menuAnchorElem ? 1 : 0.5}
            />
          </MenuButton>

          <Divider />

          <Box width="16px" />

          {/* Project name */}
          <Typography fontSize={14}>
            {appState.project
              ? appState.project.id
                ? appState.project.name
                : "Log in to save this project"
              : ""}
          </Typography>

          <Box width="12px" />

          {/* Visibility badge */}
          {appState.project?.id && (
            <ProjectVisibilityBadge>
              {`${appState.project.isPublic ? "Public" : "Private"}${
                appState.project.isPublished ? ", Published" : ""
              }`}
            </ProjectVisibilityBadge>
          )}

          <Box width="12px" />

          {/* Broadcasting badge */}
          {appState.project?.broadcasting && (
            <BroadcastingBadge onClick={showBroadcastingInfo}>
              <p>Broadcasting live MML document</p>
              <Box width="4px" />
              <Icon icon="help" size="18px" color="#00E49F" />
            </BroadcastingBadge>
          )}
        </Stack>

        {/* Right */}
        <Stack direction="row" height="100%" alignItems="center">
          {rightContent}
          <Box width="12px" />
        </Stack>

        <Menu
          anchorElement={menuAnchorElem}
          alignment="left"
          items={menuItems}
          onClose={() => setMenuAnchorElem(undefined)}
          onMenuItemClick={(item) => {
            item.onClick && item.onClick();
            setMenuAnchorElem(undefined);
          }}
        />
      </ContentContainer>
    </Container>
  );
};

export default observer(ProjectNavbar);
