import styled from "@emotion/styled";
import { DialogContentText, Stack } from "@mui/material";
import * as React from "react";
import { forwardRef, useImperativeHandle, useState } from "react";

import PlayView from "~/components/PlayView";
import appState from "~/library/appState";
import MMLWebClient from "~/library/mml/client";

const PlayViewContainer = styled.div({
  position: "relative",
  width: "100%",
  height: "0",
  paddingBottom: "56%",
  overflow: "hidden",
  borderRadius: "4px",
});

const PlayViewContent = styled.div({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: "flex",
});

export type ThumbnailPlayViewRef = {
  generateProjectThumbnail: () => string;
};

const ThumbnailPlayView = forwardRef((_, ref) => {
  const [client, setClient] = useState<MMLWebClient>();
  const socketUrl = `${window.serverConfig.EXECUTION_URL}/${appState.project?.id}`;

  const generateProjectThumbnail = () => {
    if (!client) return;

    return client.getScreenshot();
  };

  useImperativeHandle(ref, () => ({
    generateProjectThumbnail,
  }));

  return (
    <Stack spacing={1}>
      <PlayViewContainer>
        <PlayViewContent>
          <PlayView url={socketUrl} onClientCreated={setClient} />
        </PlayViewContent>
      </PlayViewContainer>
      <DialogContentText fontSize={12}>
        Use your mouse and WASD or arrow keys to adjust the camera position.
      </DialogContentText>
    </Stack>
  );
});
ThumbnailPlayView.displayName = "ThumbnailPlayView";

export default ThumbnailPlayView;
