import { IMMLScene, PositionAndRotation } from "mml-web";
import * as THREE from "three";

// TODO: This should come directly from the mml-web package
import { DragFlyCameraControls } from "./external/DragFlyCameraControls";

export class MMLStaticScene implements IMMLScene {
  public readonly element: HTMLDivElement;

  private readonly camera: THREE.PerspectiveCamera;
  private readonly audioListener: THREE.AudioListener;
  private readonly threeScene: THREE.Scene;
  private renderer: THREE.WebGLRenderer;
  private readonly rootContainer: THREE.Group;
  private controls: DragFlyCameraControls | null = null;

  private animationFrameCallback: () => void;
  private resizeListener: () => void;
  private resizeObserver: ResizeObserver;

  constructor() {
    this.element = document.createElement("div");
    this.element.style.width = "100%";
    this.element.style.height = "100%";
    this.element.style.position = "relative";

    this.rootContainer = new THREE.Group();
    this.threeScene = new THREE.Scene();
    this.threeScene.add(this.rootContainer);

    this.camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.01,
      1000,
    );
    this.renderer = this.createRenderer();

    this.audioListener = new THREE.AudioListener();

    this.camera.position.z = 10;
    this.camera.position.y = 5;

    THREE.Cache.enabled = true;

    this.resizeObserver = new ResizeObserver(() => {
      this.fitContainer();
    });
    this.resizeObserver.observe(this.element);

    this.controls = new DragFlyCameraControls(this.camera, this.element);
    if (this.controls) {
      this.controls.enable();
    }

    const clock = new THREE.Clock();

    this.animationFrameCallback = () => {
      requestAnimationFrame(this.animationFrameCallback);
      if (this.controls) {
        this.controls.update(clock.getDelta());
      }
      this.renderer.render(this.threeScene, this.camera);
    };
    requestAnimationFrame(this.animationFrameCallback);

    this.resizeListener = () => {
      this.fitContainer();
    };

    window.addEventListener("resize", this.resizeListener, false);

    this.element.appendChild(this.renderer.domElement);
    this.fitContainer();
  }

  public setControlsEnabled(enabled: boolean): void {
    if (enabled) {
      this.controls?.enable();
    } else {
      this.controls?.disable();
    }
  }

  public getThreeScene(): THREE.Scene {
    return this.threeScene;
  }

  public getRenderer(): THREE.Renderer {
    return this.renderer;
  }

  public getAudioListener(): THREE.AudioListener {
    return this.audioListener;
  }

  public getRootContainer(): THREE.Group {
    return this.rootContainer;
  }

  public getCamera(): THREE.Camera {
    return this.camera;
  }

  public getUserPositionAndRotation(): PositionAndRotation {
    return {
      position: {
        x: this.camera.position.x,
        y: this.camera.position.y,
        z: this.camera.position.z,
      },
      rotation: {
        x: this.camera.rotation.x,
        y: this.camera.rotation.y,
        z: this.camera.rotation.z,
      },
    };
  }

  private createRenderer() {
    let renderer;
    if (navigator.userAgent.includes("jsdom")) {
      renderer = {
        domElement: document.createElement("canvas"),
        setSize: () => void 0,
        render: () => void 0,
      } as unknown as THREE.WebGLRenderer;
    } else {
      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setClearColor(0xffffff, 1);
      renderer.outputColorSpace = THREE.SRGBColorSpace;
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    }
    renderer.domElement.style.pointerEvents = "none";
    return renderer;
  }

  public fitContainer() {
    if (!this) {
      console.error("MScene not initialized");
      return;
    }
    const width = this.element.clientWidth;
    const height = this.element.clientHeight;
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);
  }

  public dispose() {
    window.removeEventListener("resize", this.resizeListener);
    this.resizeObserver.disconnect();
    this.rootContainer.clear();
  }

  public prompt() {
    // no-op
  }

  public addCollider() {
    // no-op
  }

  public updateCollider() {
    // no-op
  }

  public removeCollider() {
    // no-op
  }

  public addInteraction() {
    // no-op
  }

  public updateInteraction() {
    // no-op
  }

  public removeInteraction() {
    // no-op
  }

  public getInteractions() {
    // no-op
  }

  public addInteractionListener() {
    // no-op
  }

  public removeInteractionListener() {
    // no-op
  }

  public link() {
    // no-op
  }
}
