import styled from "@emotion/styled";
import {
  Unstable_Grid2 as Grid,
  InputAdornment,
  Pagination as MuiPagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ExploreProjectSearchResult } from "mml-editor-api-schema";
import * as React from "react";
import { useEffect, useState } from "react";
import { InstantSearch } from "react-instantsearch";

import Icon from "~/components/Icon";
import Navbar from "~/components/Navbar";
import Screen from "~/components/Screen";
import ViewSwitch from "~/components/ViewSwitch";
import config from "~/config";
import { createAlgoliaSearchClient } from "~/library/algolia/algolia";
import { forkProjectModal } from "~/modals";
import { makeForkExploreProject } from "~/modals/ForkProject";
import { ExploreResults } from "~/screens/Explore/ExploreResults";
import SearchFilters from "~/screens/Explore/SearchFilters";
import { useSearch } from "~/screens/Explore/useSearch";
import Redirect from "~/screens/Redirect";

const HeaderContainer = styled.div({
  width: "100%",
  height: "64px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "40px",
});

const PaginationContainer = styled.div({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  padding: "20px",
});

const searchClient = createAlgoliaSearchClient();

const forkProject = (exploreProjectId: string) => {
  forkProjectModal({
    projectId: exploreProjectId,
    makeForkRequest: makeForkExploreProject,
  });
};

const ExploreScreen = () => {
  const [listView, setListView] = useState(
    localStorage.getItem("projectsListView") === "true",
  );

  const {
    items,
    searchTerm,
    setSearchTerm,
    debouncedSearchTerm,
    selectedFilters,
    updateSelectedFilters,
    hasRenderedInitialResults,
    currentPage,
    totalPages,
    setPage,
  } = useSearch();

  useEffect(() => {
    localStorage.setItem("projectsListView", listView ? "true" : "false");
  }, [listView]);

  if (!config.features.explorePage) {
    return <Redirect redirectTo="landingPage" />;
  }

  const noResultsFound = hasRenderedInitialResults && items.length === 0;

  return (
    <Screen navbar={<Navbar />}>
      <HeaderContainer>
        <Typography variant="h1">Explore</Typography>
        <Stack direction="row" spacing="16px">
          <TextField
            placeholder="Search..."
            value={searchTerm || ""}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="search" opacity={0.5} />
                </InputAdornment>
              ),
            }}
          />
          <ViewSwitch
            listView={listView}
            onChange={(isListView) => setListView(isListView)}
          />
        </Stack>
      </HeaderContainer>
      <Grid container columns={4} spacing="16px">
        <Grid xs={4} md={3}>
          {noResultsFound && (
            <Typography variant="h5">No results found</Typography>
          )}
          <ExploreResults
            fadeInResults={hasRenderedInitialResults}
            searchTerm={encodeURIComponent(
              `${debouncedSearchTerm} ${selectedFilters.join(" ")}`
                .trim()
                .toLowerCase(),
            )}
            exploreProjects={items}
            rowLayout={listView}
            onFork={(exploreProject: ExploreProjectSearchResult) => {
              forkProject(exploreProject.id);
            }}
          />
          {totalPages > 1 && (
            <Grid columns={4}>
              <PaginationContainer>
                <MuiPagination
                  count={totalPages}
                  page={currentPage + 1}
                  onChange={(event, page) => setPage(page - 1)} // Change page using refinePage
                  variant="outlined" // Use outlined variant for rounded look
                  shape="rounded" // Rounded shape
                />
              </PaginationContainer>
            </Grid>
          )}
        </Grid>
        <Grid xs={1} md={1}>
          <SearchFilters
            selectedFilters={selectedFilters}
            onFilterChange={updateSelectedFilters}
          />
        </Grid>
      </Grid>
    </Screen>
  );
};

const Explore = () => {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={config.algoliaIndexName}
      routing={{
        stateMapping: {
          stateToRoute(uiState) {
            const indexUiState = uiState[config.algoliaIndexName];
            return {
              q: indexUiState.query,
              page: indexUiState.page,
            };
          },
          routeToState(routeState) {
            return {
              [config.algoliaIndexName]: {
                query: routeState.q,
                page: routeState.page,
              },
            };
          },
        },
      }}
    >
      <ExploreScreen />
    </InstantSearch>
  );
};

export default Explore;
