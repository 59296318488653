type Config = {
  features: {
    publishProject: boolean;
    explorePage: boolean;
  };
  algoliaUrl: string;
  algoliaAppId: string;
  algoliaApiKey: string;
  algoliaIndexName: string;
};

const config: Config = {
  features: {
    publishProject: false,
    explorePage: window.serverConfig.ENABLE_EXPLORE || false,
  },
  algoliaUrl: window.serverConfig.ALGOLIA_URL,
  algoliaAppId: window.serverConfig.ALGOLIA_APP_ID,
  algoliaApiKey: window.serverConfig.ALGOLIA_API_KEY,
  algoliaIndexName: window.serverConfig.ALGOLIA_INDEX_NAME,
};

export default config;
