import { observer } from "mobx-react-lite";
import * as React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Screen from "~/components/Screen";
import config from "~/config";
import appState from "~/library/appState";
import Project from "~/library/project";
import serverApi from "~/library/serverApi/serverApi";
import { makeForkExploreProject } from "~/modals/ForkProject";
import Layout from "~/screens/Editor/components/Layout";
import Navbar from "~/screens/Editor/components/Navbar";
import AssetsPanel from "~/screens/Editor/panels/Assets";
import CodePanel from "~/screens/Editor/panels/Code";
import DebugPanel from "~/screens/Editor/panels/Debug";
import EditPanel from "~/screens/Editor/panels/Edit";
import InfoPanel from "~/screens/Editor/panels/Info";
import InspectorPanel from "~/screens/Editor/panels/Inspector";
import PlayPanel from "~/screens/Editor/panels/Play";
import ScenePanel from "~/screens/Editor/panels/Scene";
import Redirect from "~/screens/Redirect";

const ExploreEditor = () => {
  const urlParams = useParams();
  const navigate = useNavigate();

  const getExploreProject = async () => {
    const exploreProjectId = urlParams.exploreProjectId?.split("-").pop();
    if (!exploreProjectId) {
      navigate("/projects");
      return;
    }

    const res = await serverApi.getExploreProject({
      parameters: {
        exploreProjectId,
      },
      body: null,
    });

    if (res.code === 200) {
      appState.project = new Project(
        {
          ...res.body,
          isPublic: true,
          isPublished: false,
        },
        true,
        true,
      );
      appState.project.readOnly = true;
    } else {
      navigate("/projects");
    }
  };

  useEffect(() => {
    if (config.features.explorePage) {
      getExploreProject();
    }

    return () => {
      if (appState.project) {
        appState.project = undefined;
      }
    };
  }, []);

  if (!config.features.explorePage) {
    return <Redirect redirectTo="landingPage" />;
  }

  return (
    <Screen
      navbar={<Navbar makeForkRequest={makeForkExploreProject} />}
      fullWidth
      noPadding
    >
      <Layout
        leftPanels={[ScenePanel, AssetsPanel, InfoPanel]}
        centerTopPanels={[EditPanel, PlayPanel]}
        centerBottomPanels={[CodePanel, DebugPanel]}
        rightPanels={[InspectorPanel]}
      />
    </Screen>
  );
};

export default observer(ExploreEditor);
