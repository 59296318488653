import { Fade, Unstable_Grid2 as Grid } from "@mui/material";
import { ExploreProjectSearchResult } from "mml-editor-api-schema";
import * as React from "react";
import slugify from "slugify";

import ExploreProjectCard from "~/screens/Explore/ExploreProjectCard";

const resultFadeInDurationMs = 1_000;

type Props = {
  fadeInResults: boolean;
  searchTerm: string;
  exploreProjects: ExploreProjectSearchResult[];
  rowLayout?: boolean;
  onFork?: (project: ExploreProjectSearchResult) => void;
};

export const ExploreResults = ({
  fadeInResults,
  searchTerm,
  exploreProjects,
  rowLayout,
  onFork,
}: Props) => {
  const fadeInDuration = fadeInResults ? resultFadeInDurationMs : 0;

  return (
    <Grid container spacing={rowLayout ? "16px" : "24px"} columns={12}>
      {exploreProjects.map((exploreProject) => (
        <Fade
          in={true}
          timeout={fadeInDuration}
          key={`${searchTerm}-${exploreProject.id}`}
        >
          <Grid
            key={exploreProject.id}
            xs={rowLayout ? 12 : 4}
            lg={rowLayout ? 12 : 4}
          >
            <ExploreProjectCard
              rowLayout={rowLayout}
              project={exploreProject}
              href={`${slugify(exploreProject.name, {
                lower: true,
              })}-${exploreProject.id}`}
              onFork={() => onFork && onFork(exploreProject)}
            />
          </Grid>
        </Fade>
      ))}
    </Grid>
  );
};
