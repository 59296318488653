import styled from "@emotion/styled";
import { Card, Checkbox, FormControlLabel, Typography } from "@mui/material";
import * as React from "react";

import { mmlElements } from "~/screens/Explore/mml";

const Container = styled.aside({
  padding: "16px",
  backgroundColor: "#404040",
});

const UnorderedList = styled.ul({
  listStyle: "none",
  padding: 0,
  margin: 0,
});

const ListItem = styled.li({
  padding: "0px",
});

type Props = {
  selectedFilters: string[];
  onFilterChange: (selectedFilters: string[]) => void;
};

export default function SearchFilters({
  selectedFilters,
  onFilterChange,
}: Props) {
  const handleCheckboxChange = (element: string) => {
    const newFilters = selectedFilters.includes(element)
      ? selectedFilters.filter((filter) => filter !== element)
      : [...selectedFilters, element];

    onFilterChange(newFilters);
  };

  return (
    <Card>
      <Container>
        <Typography variant="h5" component="h2">
          Elements
        </Typography>
        <UnorderedList>
          {mmlElements.map((element) => (
            <ListItem key={element}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={selectedFilters.includes(element)}
                    onChange={() => handleCheckboxChange(element)}
                  />
                }
                label={element}
              />
            </ListItem>
          ))}
        </UnorderedList>
      </Container>
    </Card>
  );
}
