import { ExploreProjectSearchResult } from "mml-editor-api-schema";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  useHits,
  useHitsPerPage,
  usePagination,
  useSearchBox,
} from "react-instantsearch";
import { useLocation } from "react-router-dom";

import { mmlElementSet } from "~/screens/Explore/mml";

const resultsPerPage = 15;

type SearchHook = {
  items: ExploreProjectSearchResult[];
  searchTerm: string | null;
  setSearchTerm: (term: string) => void;
  debouncedSearchTerm: string | null;
  selectedFilters: string[];
  updateSelectedFilters: (filters: string[]) => void;
  hasRenderedInitialResults: boolean;
  currentPage: number;
  totalPages: number;
  setPage: (page: number) => void;
};

export function useSearch(): SearchHook {
  useHitsPerPage({
    items: [
      {
        label: `${resultsPerPage} per page`,
        value: resultsPerPage,
        default: true,
      },
    ],
  });

  const {
    currentRefinement: currentPage,
    refine: refineAlgoliaPage,
    nbPages: numberOfAlgoliaPages,
  } = usePagination();
  const { refine: refineAlgoliaQuery } = useSearchBox();
  const { items: algoliaItems } = useHits<ExploreProjectSearchResult>();
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState<string | null>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string | null>(
    null,
  );
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [hasRenderedInitialResults, setHasRenderedInitialResults] =
    useState(false);
  const hasInitialized = useRef(false);

  useEffect(() => {
    if (hasInitialized.current) {
      return;
    }

    const params = new URLSearchParams(location.search);
    const queryParam = params.get("q") || "";

    const parts = queryParam.split(" ");

    const validTags = parts.filter((tag) => mmlElementSet.has(tag));
    const mainSearchTerm = parts
      .filter((tag) => !mmlElementSet.has(tag))
      .join(" ")
      .trim();

    setSearchTerm(mainSearchTerm);
    setSelectedFilters(validTags);
    hasInitialized.current = true;
  }, [location.search]);

  useEffect(() => {
    if (algoliaItems.length > 0) {
      setHasRenderedInitialResults(true);
    }
  }, [algoliaItems]);

  useEffect(() => {
    if (searchTerm === null) return;

    if (hasRenderedInitialResults) {
      const timeout = setTimeout(() => {
        setDebouncedSearchTerm(searchTerm);
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }

    setDebouncedSearchTerm(searchTerm);
  }, [hasRenderedInitialResults, searchTerm]);

  useEffect(() => {
    if (!hasRenderedInitialResults) return;

    const combinedQuery =
      `${debouncedSearchTerm} ${selectedFilters.join(" ")}`.trim();

    refineAlgoliaQuery(combinedQuery);
  }, [debouncedSearchTerm, selectedFilters]);

  const updateSelectedFilters = useCallback((newFilters: string[]) => {
    setSelectedFilters(newFilters);
  }, []);

  const updateSearchTerm = useCallback((term: string) => {
    setSearchTerm(term);
  }, []);

  useEffect(() => {}, [algoliaItems]);

  return {
    items: algoliaItems,
    searchTerm,
    setSearchTerm: updateSearchTerm,
    debouncedSearchTerm,
    selectedFilters,
    updateSelectedFilters,
    hasRenderedInitialResults,
    currentPage: currentPage || 0,
    totalPages: numberOfAlgoliaPages,
    setPage: refineAlgoliaPage,
  };
}
