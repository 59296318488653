import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useState } from "react";
import slugify from "slugify";

import Icon from "~/components/Icon";
import appState from "~/library/appState";
import serverApi from "~/library/serverApi/serverApi";

const CustomText = styled(DialogContentText)({
  a: {
    color: "white",
  },
});

const WarningText = styled(DialogContentText)({
  color: "#EB8D2F",
  fontWeight: 500,
});

type ForkResult =
  | {
      type: "success";
      slug: string;
    }
  | {
      type: "error";
      error: Error;
    };

export type MakeForkRequest = (projectId: string) => Promise<ForkResult>;

export async function makeForkProjectRequest(
  projectId: string,
): Promise<ForkResult> {
  const res = await serverApi.forkProject({
    parameters: {
      projectId,
    },
    body: {},
  });
  if (res.code === 200) {
    const slug = `${slugify(res.body.name, {
      lower: true,
    })}-${res.body.id}`;

    return {
      type: "success",
      slug,
    };
  }

  return {
    type: "error",
    error: new Error(res.body.message),
  };
}

export async function makeForkExploreProject(
  exploreProjectId: string,
): Promise<ForkResult> {
  const res = await serverApi.forkExploreProject({
    parameters: {
      exploreProjectId,
    },
    body: {},
  });
  if (res.code === 200) {
    const slug = `${slugify(res.body.name, {
      lower: true,
    })}-${res.body.id}`;

    return {
      type: "success",
      slug,
    };
  }

  return {
    type: "error",
    error: new Error(res.body.message),
  };
}

type Props = {
  open: boolean;
  onClose: () => void;
  projectId: string;
  makeForkRequest: MakeForkRequest;
};

const ForkProjectModal = ({
  open,
  onClose,
  projectId,
  makeForkRequest,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState<string>();

  const forkProject = async () => {
    setErrorMessage(undefined);
    const result = await makeForkRequest(projectId);
    switch (result.type) {
      case "success":
        // Open forked project in new window
        window.open(
          `${window.location.origin}/projects/${result.slug}`,
          "_blank",
          "noopener",
        );

        onClose();
        break;
      case "error":
        console.error(result.error);
        setErrorMessage("There was a problem forking the project.");
        onClose();
        break;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Fork project</DialogTitle>

      <DialogContent>
        <Stack spacing={2}>
          <CustomText fontSize={13}>
            Fork this project to create your own copy of it that you can modify.
          </CustomText>

          {/* Require sign in */}
          {!appState.user && (
            <>
              <WarningText fontSize={14}>
                You need to be signed in to fork this project.
              </WarningText>

              <div>
                <Button
                  variant="contained"
                  href={`/auth/login/google`}
                  target="_blank"
                  startIcon={<Icon icon="google" />}
                >
                  Sign in with Google
                </Button>
              </div>
            </>
          )}

          {errorMessage && (
            <DialogContentText color="#E05E57">
              {errorMessage}
            </DialogContentText>
          )}

          {/* Fork */}
          {appState.user && (
            <div>
              <Button
                variant="contained"
                onClick={forkProject}
                startIcon={<Icon icon="fork" />}
              >
                Fork project
              </Button>
            </div>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default observer(ForkProjectModal);
