import { ThemeProvider } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { createRoot } from "react-dom/client";

import DeleteStaticVersionModal from "~/modals/DeleteStaticVersion";
import FeedbackModal from "~/modals/Feedback";
import PublishExploreProjectModal from "~/modals/PublishExploreProject";
import theme from "~/theme";

import BroadcastingModal from "./Broadcasting";
import CreateAssetModal from "./CreateAsset";
import DeleteAssetModal from "./DeleteAsset";
import DeleteProjectModal from "./DeleteProject";
import ExploreModal from "./Explore";
import ForkProjectModal from "./ForkProject";
import RenameProjectModal from "./RenameProject";
import ShareProjectModal from "./ShareProject";
import StaticVersionsModal from "./StaticVersions";
import UpdateProjectDescriptionModal from "./UpdateProjectDescription";
import UpdateProjectThumbnailModal from "./UpdateProjectThumbnail";

export type PropsOfModal<
  M extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>,
> = JSX.LibraryManagedAttributes<M, Partial<React.ComponentProps<M>>>;

const container = document.getElementById("modal");
const root = createRoot(container!);

const ModalWrapper = (props: any) => {
  const [open, setOpen] = useState(true);
  const { Modal, ...rest } = props;

  const onClose = () => {
    setOpen(false);
    props.onClose?.();

    setTimeout(() => {
      root.render(null);
    }, 300);
  };

  return <Modal open={open} {...rest} onClose={onClose} />;
};

export const openModal = <M extends React.ElementType>(
  Modal: M,
  props: PropsOfModal<M>,
) => {
  root.render(
    <ThemeProvider theme={theme}>
      <ModalWrapper Modal={Modal} {...props} />
    </ThemeProvider>,
  );
};

export const renameProjectModal = (
  props: PropsOfModal<typeof RenameProjectModal>,
) => {
  openModal(RenameProjectModal, props);
};

export const deleteProjectModal = (
  props: PropsOfModal<typeof DeleteProjectModal>,
) => {
  openModal(DeleteProjectModal, props);
};

export const shareProjectModal = (
  props: PropsOfModal<typeof ShareProjectModal>,
) => {
  openModal(ShareProjectModal, props);
};

export const staticVersionsModal = (
  props: PropsOfModal<typeof StaticVersionsModal>,
) => {
  openModal(StaticVersionsModal, props);
};

export const exploreModal = (props: PropsOfModal<typeof ExploreModal>) => {
  openModal(ExploreModal, props);
};

export const publishExploreProjectModal = (
  props: PropsOfModal<typeof ExploreModal>,
) => {
  openModal(PublishExploreProjectModal, props);
};

export const broadcastingModal = (
  props: PropsOfModal<typeof BroadcastingModal>,
) => {
  openModal(BroadcastingModal, props);
};

export const forkProjectModal = (
  props: PropsOfModal<typeof ForkProjectModal>,
) => {
  openModal(ForkProjectModal, props);
};

export const updateProjectDescriptionModal = (
  props: PropsOfModal<typeof UpdateProjectDescriptionModal>,
) => {
  openModal(UpdateProjectDescriptionModal, props);
};

export const updateProjectThumbnailModal = (
  props: PropsOfModal<typeof UpdateProjectThumbnailModal>,
) => {
  openModal(UpdateProjectThumbnailModal, props);
};

export const createAssetModal = (
  props: PropsOfModal<typeof CreateAssetModal>,
) => {
  openModal(CreateAssetModal, props);
};

export const deleteAssetModal = (
  props: PropsOfModal<typeof DeleteAssetModal>,
) => {
  openModal(DeleteAssetModal, props);
};

export const deleteStaticVersionModal = (
  props: PropsOfModal<typeof DeleteStaticVersionModal>,
) => {
  openModal(DeleteStaticVersionModal, props);
};

export const feedbackModal = (props: PropsOfModal<typeof FeedbackModal>) => {
  openModal(FeedbackModal, props);
};
