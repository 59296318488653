import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { ExploreProject } from "mml-editor-api-schema";
import * as React from "react";
import { useState } from "react";
import slugify from "slugify";

import URLField from "~/components/URLField";
import { timeAgo } from "~/library/dateUtils";
import DeleteExploreProjectModal from "~/modals/DeleteExploreProject";

const bounce = keyframes`
    from {
        max-height: 0px;
    }

    to {
        max-height: 100px
    }
`;

const AnimatedAddingContainer = styled.div<{ added?: boolean }>((props) => ({
  overflow: "hidden",
  animation: props.added ? `${bounce} 1s ease` : "",
}));

const Container = styled.div<{ added?: boolean }>((props) => ({
  position: "relative",
  boxSizing: "border-box",
  width: "100%",
  padding: "6px",
  borderRadius: "4px",
  backgroundColor: props.added
    ? "rgba(0, 228, 159, 0.1)"
    : "rgba(255, 255, 255, 0.1)",
  border: props.added
    ? "1px solid rgba(0, 228, 159, 0.3)"
    : "1px solid rgba(0, 0, 0, 1)",
  transition: "background-color 0.1s",
  marginTop: "6px",

  "&:hover": {
    backgroundColor: props.added
      ? "rgba(0, 228, 159, 0.2)"
      : "rgba(255, 255, 255, 0.1)",
  },
}));

const IdentityRow = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  flexDirection: "row",
  paddingBottom: "6px",
});

const StaticVersionId = styled(Typography)({
  fontSize: "13px",
  color: "rgba(255, 255, 255, 0.7)",
  fontWeight: 700,
  lineHeight: 1,
  overflowWrap: "anywhere",
});

const StaticVersionCreatedTime = styled(Typography)({
  fontSize: "12px",
  color: "rgba(255, 255, 255, 0.5)",
  lineHeight: 1,
});

const ContentRow = styled.div({
  display: "flex",
  boxSizing: "border-box",
  width: "100%",
});

const DeleteButton = styled(Button)(() => ({
  height: "40px",
  marginLeft: "6px",
})) as typeof Button;

const ExploreProjectItem = ({
  projectId,
  exploreProject,
  onDelete,
}: {
  projectId: string;
  exploreProject: ExploreProject & { added?: boolean };
  onDelete: (id: string) => void;
}) => {
  const [deletingModalOpen, setDeletingModalOpen] = useState(false);

  return (
    <>
      {deletingModalOpen && (
        <DeleteExploreProjectModal
          onClose={() => {
            setDeletingModalOpen(false);
          }}
          onDelete={(exploreProjectId) => {
            onDelete(exploreProjectId);
          }}
          open={true}
          projectId={projectId}
          exploreProjectId={exploreProject.id}
        />
      )}
      <AnimatedAddingContainer added={exploreProject.added}>
        <Container added={exploreProject.added}>
          <IdentityRow>
            <StaticVersionId>{exploreProject.name}</StaticVersionId>
            <StaticVersionCreatedTime>
              {timeAgo(new Date(exploreProject.createdAt))}
            </StaticVersionCreatedTime>
          </IdentityRow>
          <ContentRow>
            <URLField
              url={
                location.protocol +
                "//" +
                location.host +
                "/explore/" +
                `${slugify(exploreProject.name, {
                  lower: true,
                })}-${exploreProject.id}`
              }
            />
            <DeleteButton
              variant="contained"
              color="error"
              onClick={() => {
                setDeletingModalOpen(true);
              }}
            >
              Delete
            </DeleteButton>
          </ContentRow>
        </Container>
      </AnimatedAddingContainer>
    </>
  );
};

export default ExploreProjectItem;
