import styled from "@emotion/styled";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import * as React from "react";

import serverApi from "~/library/serverApi/serverApi";

const WarningText = styled(DialogContentText)({
  color: "#EB8D2F",
  fontWeight: 500,
});

const DeleteProjectModal = ({
  open,
  onClose,
  projectId,
  onDelete,
}: {
  open: boolean;
  onClose: () => void;
  projectId: string;
  onDelete: () => void;
}) => {
  const [error, setError] = React.useState<string | null>(null);

  const deleteProject = async () => {
    setError(null);
    const res = await serverApi.deleteProject({
      parameters: {
        projectId,
      },
      body: null,
    });
    if (res.code === 200) {
      onDelete();
      onClose();
    } else {
      setError(res.body.message);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Delete project</DialogTitle>

      <DialogContent>
        <DialogContentText>
          The project will become inaccessible and all of its data will be
          removed permanently. Are you sure you wish to continue?
        </DialogContentText>
      </DialogContent>

      <DialogActions
        sx={{ padding: "0 24px 20px 24px", justifyContent: "space-between" }}
      >
        <WarningText>{error}</WarningText>

        <Box sx={{ display: "flex", gap: 1 }}>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={deleteProject}>
            Delete
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteProjectModal;
