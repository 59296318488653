import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { ExploreProject } from "mml-editor-api-schema";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";

import ExploreProjectItem from "~/components/ExploreProjectItem";
import appState from "~/library/appState";
import serverApi from "~/library/serverApi/serverApi";
import { publishExploreProjectModal } from "~/modals/index";

const Divider = styled.hr({
  opacity: 0.2,
  margin: "24px 0",
});

type AddableExploreProject = ExploreProject & {
  added?: boolean;
};

const ExploreModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [existingExploreProjects, setExistingExploreProjects] = useState<
    Array<AddableExploreProject>
  >([]);
  const [loading, setLoading] = useState(true);

  const project = appState.project!;

  const fetchExistingExploreProjects = useCallback(async () => {
    const res = await serverApi.listProjectExploreProjects({
      parameters: {
        projectId: project.id,
      },
      body: null,
    });
    if (res.code === 200) {
      setExistingExploreProjects(res.body.projects);
    }
    setLoading(false);
  }, [project.id]);

  useEffect(() => {
    // TODO: Display an error to the user
    fetchExistingExploreProjects().catch(console.error);
  }, [project.id, fetchExistingExploreProjects]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Explore</DialogTitle>

      <DialogContent>
        <Stack spacing={1}>
          <DialogContentText fontSize={13}>
            Publish your project to the MML Explore page.
          </DialogContentText>
          <div>
            <Button
              variant="contained"
              onClick={() => {
                publishExploreProjectModal({});
              }}
            >
              Publish
            </Button>
          </div>
        </Stack>

        <Divider />

        <Stack>
          <Typography>Existing Explore Projects</Typography>
          {loading && (
            <DialogContentText fontSize={13}>Loading...</DialogContentText>
          )}
          {!loading && existingExploreProjects.length === 0 && (
            <DialogContentText fontSize={13}>
              No existing Explore projects.
            </DialogContentText>
          )}
          {!loading &&
            existingExploreProjects.map(
              (exploreProject: AddableExploreProject) => (
                <ExploreProjectItem
                  key={exploreProject.id}
                  projectId={project.id}
                  exploreProject={exploreProject}
                  onDelete={(id: string) => {
                    setExistingExploreProjects((existing) =>
                      existing.filter((p) => p.id !== id),
                    );
                    fetchExistingExploreProjects().catch(console.error);
                  }}
                />
              ),
            )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default observer(ExploreModal);
