import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import * as React from "react";
import { useCallback } from "react";

import serverApi from "~/library/serverApi/serverApi";

const DeleteExploreProjectModal = ({
  open,
  onClose,
  projectId,
  exploreProjectId,
  onDelete,
}: {
  open: boolean;
  onClose: () => void;
  projectId: string;
  exploreProjectId: string;
  onDelete: (exploreProjectId: string) => void;
}) => {
  const deleteExploreProject = useCallback(async () => {
    const res = await serverApi.deleteExploreProject({
      parameters: {
        projectId,
        exploreProjectId,
      },
      body: null,
    });
    if (res.code === 200) {
      onDelete(exploreProjectId);
      onClose();
    }
  }, [projectId, exploreProjectId, onDelete, onClose]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Delete Explore project?</DialogTitle>

      <DialogContent>
        <DialogContentText>
          This Explore project will no longer be accessible. Are you sure you
          wish to continue?
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ padding: "0 24px 20px 24px" }}>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={deleteExploreProject}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteExploreProjectModal;
