import styled from "@emotion/styled";
import { Stack, Tooltip, Typography } from "@mui/material";
import * as React from "react";

import Icon from "~/components/Icon";

const ELEM_DESCRIPTIONS: any = {
  "m-group": "Group of objects",
  "m-cube": "Cube primitive",
  "m-sphere": "Sphere primitive",
  "m-cylinder": "Cylinder primitive",
  "m-light": "Configurable light source",
  "m-plane": "Flat plane primitive",
  "m-model": "3D model",
  "m-character": "Character made of multiple 3D models",
  "m-frame": "Nested MML object",
  "m-audio": "Audio",
  "m-image": "Image",
  "m-video": "Video",
  "m-label": "Text label",
  "m-position-probe": "Detection of user position",
  "m-prompt": "User input prompt",
  "m-interaction": "Interactive user action",
  "m-chat-probe": "Chat message receiver",
  "m-link": "Link to a web address",
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled.div(({ theme }) => ({
  height: "40px",
  width: "40px",
  cursor: "pointer",
  borderRadius: "4px",
  backgroundColor: "#444444",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: `1px solid transparent`,
  transition: "all 0.1s",
  overflow: "hidden",
  "&:hover": {
    backgroundColor: "#555555",
  },
  "&:active": {
    backgroundColor: "#666666",
    // borderColor: theme.palette.primary.light
  },
}));

const TooltipTag = styled(Typography)({
  fontSize: "13px",
  color: "rgba(255, 255, 255, 1)",
  fontWeight: 700,
  fontFamily: "monospace",
  lineHeight: 1,
});

const TooltipDescription = styled(Typography)({
  fontSize: "12px",
  color: "rgba(255, 255, 255, 0.7)",
  lineHeight: 1,
});

const NewElemButton = ({
  tag,
  onClick,
}: {
  tag: string;
  onClick: (code: string) => void;
}) => {
  const tooltipContent = (
    <Stack direction="column" alignItems="flex-start" spacing={1}>
      <TooltipTag style={{ fontFamily: "monospace" }}>&lt;{tag}&gt;</TooltipTag>
      <TooltipDescription>{ELEM_DESCRIPTIONS[tag]}</TooltipDescription>
    </Stack>
  );

  const getSourceCode = (): string => {
    return `<${tag}></${tag}>`;
  };

  const createDragGhost = () => {
    const elem = document.createElement("div");
    elem.setAttribute("id", "create-object-drag-ghost");

    elem.innerHTML = `<span style="font-weight: 700;">&lt;${tag}&gt;</span>`;

    elem.style.backgroundColor = "black";
    elem.style.padding = "8px";
    elem.style.borderRadius = "4px";
    elem.style.fontSize = "13px";
    elem.style.width = "fit-content";

    document.body.appendChild(elem);
    return elem;
  };

  const removeDragGhost = () => {
    document.getElementById("create-object-drag-ghost")?.remove();
  };

  return (
    <Tooltip
      title={tooltipContent}
      disableInteractive
      enterDelay={500}
      placement="top"
      disableFocusListener
    >
      <Container
        draggable
        onDragStart={(evt) => {
          const ghost = createDragGhost();
          evt.dataTransfer.setDragImage(ghost, 0, 40);
          evt.dataTransfer.setData("text/plain", getSourceCode());
        }}
        onDragEnd={() => {
          removeDragGhost();
        }}
        onClick={() => {
          onClick(getSourceCode());
        }}
      >
        <Icon icon={tag} size="18px" />
      </Container>
    </Tooltip>
  );
};

export default NewElemButton;
