import { searchClient, SearchClient } from "@algolia/client-search";

import config from "~/config";

export function createAlgoliaSearchClient(): SearchClient {
  if (!config.features.explorePage) {
    // Create an Algolia search client misconfigured to black-hole traffic, in the event it is
    // accidentally used
    return searchClient(config.algoliaAppId, config.algoliaApiKey, {
      hosts: [{ url: "localhost", accept: "readWrite", protocol: "http" }],
    });
  }

  if (config.algoliaUrl) {
    const parsedUrl = new URL(config.algoliaUrl);
    return searchClient(config.algoliaAppId, config.algoliaApiKey, {
      hosts: [
        {
          url: parsedUrl.hostname,
          accept: "readWrite",
          protocol: parsedUrl.protocol.replace(":", "") as "http" | "https",
          port: parseInt(parsedUrl.port),
        },
      ],
    });
  }

  return searchClient(config.algoliaAppId, config.algoliaApiKey);
}
